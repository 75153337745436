<template>
  <app-view>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary mt-2 mb-50">
      {{ $t('pages.crops.name') }}
    </h1>
    <h4
      v-if="crop !== null"
      class="text-ellipsis"
    >
      <span class="mr-75">{{ crop.emoji !== null && crop.emoji !== undefined ? crop.emoji : '' }}</span> {{ crop.name }}
    </h4>
    <h5 class="mt-3">
      {{ $t('pages.crop-grades.add-edit.name') }}
    </h5>

    <div class="mt-0 pt-75 mb-3">
      <template v-if="loader">
        <div class="mt-2 d-flex align-items-center justify-content-center my-5 py-2">
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="mt-0"
        >
          <b-row>
            <b-col
              class="mb-1"
            >
              <div
                v-if="loader"
                class="mt-4 mb-4 pt-3 pb-3 d-flex align-items-center justify-content-center flex-fill"
              >
                <b-spinner
                  variant="primary"
                  type="grow"
                  :label="$t('therms.loading')"
                />
              </div>
              <template v-else>
                <validation-observer
                  ref="addForm"
                  #default="{invalid}"
                >
                  <b-form
                    class="animate__animated"
                    :class="{'animate__shakeX':error}"
                    @submit.prevent="submit"
                  >
                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.crop')+' : '"
                      label-for="crop"
                    >
                      <b-form-input
                        id="crop"
                        size="lg"
                        :disabled="true"
                        :value="crop?.name"
                      />
                    </b-form-group>

                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.name')+' : '"
                      label-for="name"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        vid="name"
                        rules="required|min:1|max:45"
                      >
                        <b-form-input
                          id="name"
                          v-model="name"
                          size="lg"
                          minlength="1"
                          maxlength="45"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('pages.crop-grades.add-edit.form.name.placeholder')"
                        />
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.weight')+' : '"
                      label-for="weight"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="weight"
                        vid="weight"
                        rules="required"
                      >
                        <b-input-group :append="$t('units.kg')">
                          <b-form-input
                            id="weight"
                            v-model="weight"
                            size="lg"
                            type="number"
                            step="any"
                            :state="errors.length > 0 ? false:null"
                            placeholder="4"
                          />
                        </b-input-group>
                      </validation-provider>
                    </b-form-group>

                    <div class="mt-2 pt-75">
                      <touch-button
                        size="sm"
                        :disabled="invalid || error"
                        :disable="invalid || error"
                      >
                        {{ $t('therms.save') }}
                      </touch-button>
                    </div>
                  </b-form>
                </validation-observer>
              </template>
            </b-col>
          </b-row>

        </div>
      </template>
    </div>
  </app-view>
</template>

<script>
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import {
  BSpinner,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'
import { required, max, min } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Dialog } from '@capacitor/dialog'

export default {
  components: {
    NavButtonBar,
    NavButton,
    TouchButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      // ui
      error: false,
      loader: false,

      // data
      crop: null,
      name: '',
      weight: '',

      // validation rules
      required,
      max,
      min,
    }
  },
  watch: {
    error() {
      setTimeout(() => {
        if (this.error) {
          this.error = false
        }
      }, 800)
    },
  },
  async mounted() {
    this.error = false
    await this.load()
  },
  methods: {
    /**
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    async load(force = false) {
      this.loader = true
      try {
        if (force) {
          this.crop = null
        }

        // eslint-disable-next-line radix
        this.crop = await this.$store.dispatch('crops/find', { id: parseInt(this.$router.currentRoute.params?.cropId) })
        // eslint-disable-next-line radix,prefer-destructuring
      } catch (e) {
        this.error = true
        throw e
      } finally {
        this.loader = false
      }
    },
    async submit() {
      try {
        this.loader = true
        const result = await this.$store.dispatch('crops/addCropGrade', {
          cropId: this.crop.id,
          name: this.name,
          weight: this.weight,
        })

        if (!result) {
          this.error = true
          await Dialog.alert({
            title: this.$t('therms.error').toString(),
            message: this.$t('actions.crop-grade-add-error.message').toString(),
            buttonTitle: this.$t('therms.close').toString(),
          })
          this.loader = false
          return
        }

        await this.$router.back()
      } catch (err) {
        this.error = true
        throw err
      } finally {
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
